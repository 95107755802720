





























import ProfileCard from './components/ProfileCard.vue'
import mixins from 'vue-typed-mixins'
import Employees from '@/calendesk/sections/section/employees/mixins/Employees'
export default mixins(Employees).extend({
  name: 'Employees1',
  components: { ProfileCard }
})
